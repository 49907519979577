import { faArrowRight, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, MenuItem, Modal, Select } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import { ProjectApi } from "services/Project";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
};

export default function () {
  const [loading, setLoading] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingSteps, setLoadingSteps] = useState(false);
  const [projects, setProjects] = useState([]);
  const [steps, setSteps] = useState([]);
  const [show, setShow] = useState(false);

  const loadProjects = async () => {
    setLoadingProjects(true);
    const response = await ProjectApi.list();
    setLoadingProjects(false);

    if (!response.ok) {
      return;
    }

    setProjects(response.projects);
  };

  const loadSteps = async (projectId) => {
    setLoadingSteps(true);
    const response = await ProjectApi.listStepsFromProject(projectId);
    setLoadingSteps(false);

    if (!response.ok) {
      return;
    }

    setSteps(response.steps);
    console.log("Steps:", response.steps.length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const stepId = e.target["techgest--input-step"].value;
    const progress = Number(e.target["techgest--input-progress"].value);
    const response =
      progress > 0
        ? await ProjectApi.addProgressToStep(stepId, progress)
        : await ProjectApi.removeProgressFromStep(stepId, progress);
    setLoading(false);

    if (!response.ok) {
      alert(response.message);
      return;
    }

    alert("Avanco regitrado com sucesso.");
    setShow(false);
  };

  useEffect(() => {
    loadProjects();
  }, []);

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="5rem"
        height="5rem"
        bgColor="green"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="white"
        sx={{ cursor: "pointer" }}
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </SoftBox>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <SoftBox sx={{ ...style }}>
          <p id="child-modal-description">Registrar avanco:</p>

          <Divider />

          <SoftBox component="form" role="form" onSubmit={handleSubmit}>
            {!loadingProjects && (
              <SoftBox mb={2}>
                <SoftBox mb={2}>
                  <SoftBox mb={1}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Obra:
                    </SoftTypography>
                  </SoftBox>
                  <Select
                    name="techgest--input-project"
                    id="techgest--input-project"
                    required
                    defaultValue={"invalid"}
                    onChange={async (e) => {
                      await loadSteps(e.target.value);
                    }}
                  >
                    <MenuItem value="invalid">Selecione uma obra</MenuItem>
                    {projects.map((p) => (
                      <MenuItem key={`project-${p.id}`} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </SoftBox>
              </SoftBox>
            )}

            {
              <SoftBox mb={2}>
                <SoftBox mb={2}>
                  <SoftBox mb={1}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Etapa:
                    </SoftTypography>
                  </SoftBox>
                  <Select
                    name="techgest--input-step"
                    id="techgest--input-step"
                    required
                    defaultValue={"invalid"}
                  >
                    <MenuItem value="invalid">Selecione uma etapa</MenuItem>
                    {steps.map((s) => (
                      <MenuItem key={`step-${s.id}`} value={s.id}>
                        {s.name} ({s.progress}%)
                      </MenuItem>
                    ))}
                  </Select>
                </SoftBox>
              </SoftBox>
            }

            {
              <SoftBox mb={2}>
                <SoftBox mb={2}>
                  <SoftBox mb={1}>
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Progresso:
                    </SoftTypography>
                  </SoftBox>
                  <SoftInput
                    type="number"
                    name="techgest--input-progress"
                    id="techgest--input-progress"
                    step="1"
                    placeholder="0"
                    disabled={
                      loadingProjects || loadingSteps || steps.length < 1 || projects.length < 1
                    }
                  />
                </SoftBox>
              </SoftBox>
            }

            <Divider />

            <SoftBox display="flex">
              <SoftBox mr={2}>
                <SoftButton type="button" color="white" onClick={() => setShow(false)} mr={2}>
                  Cancelar
                </SoftButton>
              </SoftBox>

              {!loadingProjects && (
                <SoftBox mr={2}>
                  <SoftButton type="submit" color="success" disabled={loading}>
                    Salvar{" "}
                    <FontAwesomeIcon
                      style={{ marginLeft: 10 }}
                      icon={loading ? faSpinner : faSave}
                      spin={loading}
                    />
                  </SoftButton>
                </SoftBox>
              )}
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Modal>
    </>
  );
}
