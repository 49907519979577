import React, { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "services/Auth";
import { AuthService } from "services/Auth";

export const useAuth = () => {
  const navigate = useNavigate();

  const logout = async () => {
    const response = await AuthApi.logout();

    if (!response.ok) {
      alert(response.message);
      return;
    }

    navigate("/login");
  };

  useLayoutEffect(() => {
    const isLogged = AuthService.isLogged();

    if (!isLogged) {
      navigate("/login");
    }
  }, []);

  return {
    logout,
  };
};
