import { HTTP_CREATED, HTTP_OK, HTTP_ACCEPTED } from "config/constants/http";
import { BaseApi } from "./BaseApi";

export const ProjectApi = {
  getBySlug: async (slug) => {
    const response = await BaseApi.make("/api/projects/" + slug, "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      ...response.data,
    };
  },
  list: async () => {
    const response = await BaseApi.make("/api/projects", "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      projects: response.data.projects,
    };
  },
  create: async (data) => {
    const response = await BaseApi.make("/api/projects", "POST", {
      ...data,
    });

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      project: response.data.project,
    };
  },
  update: async (id, data) => {
    const response = await BaseApi.make("/api/projects/" + id, "PUT", {
      ...data,
    });

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      project: response.data.project,
    };
  },
  destroy: async (id) => {
    const response = await BaseApi.make("/api/projects/" + id, "DELETE");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      project: response.data.project,
    };
  },
  createEmptyStep: async (id) => {
    const response = await BaseApi.make("/api/steps/" + id + "/empty", "POST");

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
    };
  },
  createEmptyStepInside: async (id, parentId) => {
    const params = new URLSearchParams();
    params.append("parentId", parentId);
    const response = await BaseApi.make("/api/steps/" + id + "/empty?" + params, "POST");

    if (response.status != HTTP_CREATED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
    };
  },
  updateStep: async (id, payload) => {
    const response = await BaseApi.make("/api/steps/" + id, "PUT", payload);

    if (response.status != HTTP_ACCEPTED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
    };
  },
  deleteStep: async (id) => {
    const response = await BaseApi.make("/api/steps/" + id, "DELETE");

    if (response.status != HTTP_ACCEPTED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
    };
  },
  addProgressToStep: async (id, progress) => {
    const response = await BaseApi.make("/api/steps/" + id + "/progress", "POST", {
      progress,
    });

    if (response.status != HTTP_ACCEPTED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
    };
  },
  removeProgressFromStep: async (id, progress) => {
    const response = await BaseApi.make("/api/steps/" + id + "/progress", "DELETE", {
      progress,
    });

    if (response.status != HTTP_ACCEPTED) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
    };
  },
  listStepsFromProject: async (id) => {
    const response = await BaseApi.make("/api/steps/project/" + id, "GET");

    return {
      ok: true,
      steps: response.data.steps || [],
    };
  },
};
