import { useState, useEffect } from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Soft UI Dashboard React context
import { useSoftUIController, setOpenConfigurator } from "context";

function Configurator() {
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator } = controller;
  const [disabled, setDisabled] = useState(false);

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const isEapPage = window.location.href.includes("/eap");

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox>
          <SoftTypography variant="h5">Painel de Controle</SoftTypography>
          <SoftTypography variant="body2" color="text">
            Configuracoes de projeto
          </SoftTypography>
        </SoftBox>

        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </SoftBox>

      <Divider />

      <SoftBox pt={1.25} pb={3} px={3}>
        <SoftTypography variant="body2" color="text">
          Casa 01 - Loteamento
        </SoftTypography>
        <SoftBox mt={3} lineHeight={1}>
          <SoftButton
            color={isEapPage ? "warning" : "white"}
            variant={isEapPage ? "gradient" : "contained"}
            disabled={false}
            fullWidth
            href="/obras/obra-recem-criada-2024/eap"
          >
            EAP
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} lineHeight={1}>
          <SoftButton
            color={isEapPage ? "warning" : "white"}
            variant={isEapPage ? "gradient" : "contained"}
            onClick={() => {}}
            disabled={false}
            fullWidth
          >
            Financeiro
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} lineHeight={1}>
          <SoftButton
            color={isEapPage ? "warning" : "white"}
            variant={isEapPage ? "gradient" : "contained"}
            onClick={() => {}}
            disabled={false}
            fullWidth
          >
            Documentos
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
