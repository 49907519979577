import { X_XSRF_TOKEN_COOKIE_NAME, IS_LOGGED_STORAGE_KEY } from "config/constants/auth";
import { HTTP_UNAUTHORIZED } from "config/constants/http";

const BASE_HEADERS = {
  accept: "application/json",
  "content-type": "application/json",
  "x-app-version": "Techgest-Frontent--0.0.1-beta",
};

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const prepare = async () => {
  const token = getCookie(X_XSRF_TOKEN_COOKIE_NAME);

  if (token) {
    // return;
  }

  await fetch(`${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`, {
    credentials: "include",
  });
};

export const BaseApi = {
  make: async (url, method = "GET", body = {}, headers = {}) => {
    await prepare();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
        method,
        headers: {
          ...BASE_HEADERS,
          ...headers,
          "X-XSRF-TOKEN": getCookie(X_XSRF_TOKEN_COOKIE_NAME),
        },
        body: method !== "GET" ? JSON.stringify(body) : null,
        credentials: "include",
      });

      if (response.status == HTTP_UNAUTHORIZED) {
        localStorage.removeItem(IS_LOGGED_STORAGE_KEY);
        window.location.href = "/entrar";
      }

      const data = await response.json();

      return {
        status: response.status,
        data,
      };
    } catch (e) {
      console.error(e);
      return {
        status: 500,
        data: {},
      };
    }
  },
};
