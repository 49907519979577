import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";

import OrderOverview from "layouts/dashboard/components/OrderOverview";

import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { ProjectApi } from "services/Project";
import { Card } from "@mui/material";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftButton from "components/SoftButton";

function ProjectDetails() {
  const { slug } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetchProject = async () => {
    const response = await ProjectApi.getBySlug(slug);
    setLoading(false);

    if (!response.ok) {
      return;
    }

    setData(response);
  };

  const metricsToChart = useMemo(() => {
    if (!data.metrics) {
      return {
        labels: [],
        data: [],
      };
    }

    const labels = data.metrics.dates;
    const datasets = {
      label: "Avancos",
      data: labels.map((label) =>
        data.metrics.progresses[label] ? data.metrics.progresses[label].length : 0
      ),
    };

    return {
      labels,
      datasets,
    };
  }, [data]);

  const metricsToItems = useMemo(() => {
    if (!data.metrics) {
      return {
        labels: [],
        data: [],
      };
    }

    const items = [
      {
        icon: { color: "primary", component: "library_books" },
        label: "etapas finalizadas",
        progress: {
          content: data.metrics.finishedSteps,
          percentage: Math.floor((data.metrics.finishedSteps / data.metrics.totalSteps) * 100),
        },
      },
    ];

    return items;
  }, [data]);

  useEffect(() => {
    fetchProject();
  }, []);

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <h1>Carregando..</h1>
      </DashboardLayout>
    );
  }

  if (!loading && !data.project) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <h1>Obra não encontrada</h1>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadCrumbRoutes={[
          {
            name: "Obras",
            uri: "/obras",
          },
          {
            name: data.project.name,
            uri: `/obras/${data.project.slug}`,
          },
        ]}
      />
      <SoftTypography>
        <strong>{data.project.name}</strong>
      </SoftTypography>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Custo estimado" }}
                count={"R$ " + data.project.estimatedCost.toLocaleString("pt-BR")}
                icon={{ color: "warning", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Custo real" }}
                count={"R$ 0"}
                icon={{
                  color: "warning",
                  component: "paid",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Etapas em andamento" }}
                count={data.metrics.inprogressSteps}
                icon={{ color: "warning", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Etapas concluídas" }}
                count={data.metrics.finishedSteps}
                icon={{ color: "warning", component: "public" }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart title="Avancos" chart={metricsToChart} items={metricsToItems} />
            </Grid>
            <Grid item xs={12} lg={7}>
              <Card className="h-100" style={{ marginBottom: 10, padding: 10 }}>
                <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                  <SoftBox>
                    <SoftBox pt={3} px={3}>
                      <SoftTypography variant="h6" fontWeight="medium">
                        EAP
                      </SoftTypography>
                      <SoftBox mt={1} mb={2}>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                          {data.metrics.totalSteps} etapas no total. Progresso atual em{" "}
                          {data.project.progress}%
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox>
                      <SoftButton
                        href={`/obras/${data.project.slug}/eap`}
                        target={`eap-${data.project.id}`}
                        type="submit"
                        color="info"
                      >
                        Gerenciar EAP
                        <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faArrowTrendUp} />
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Card>
              <OrderOverview />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProjectDetails;
