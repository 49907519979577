// @mui material components
import Card from "@mui/material/Card";
import {
  Alert,
  Divider,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useEffect, useMemo, useState } from "react";
import SoftInput from "components/SoftInput";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import SoftProgress from "components/SoftProgress";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import StatesData from "resources/data/br-states.json";

import { ProjectApi } from "services/Project";
import { CurrencyInput } from "react-currency-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner, faStepForward, faTrashCan } from "@fortawesome/free-solid-svg-icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
};

function Projects() {
  const [creating, setCreating] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    severity: "success",
  });
  const [cities, setCities] = useState(StatesData.states[0].cities);
  const [projects, setProjects] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentProject, setCurrentProject] = useState({});
  const [deleting, setDeleting] = useState(null);
  const [loading, setLoading] = useState(true);

  const resetMessage = () => setMessage({ text: "", severity: "" });

  const handleProjectCreation = async (e) => {
    e.preventDefault();
    setCreating(true);

    const name = e.target["techgest--input-projectName"].value;
    const alias = e.target["techgest--input-projectAlias"].value;
    const clientName = e.target["techgest--input-projectClientName"].value;
    const estimatedCost = e.target["techgest--input-projectEstimatedCost"].value;
    const initialStatus = e.target["techgest--input-projectInitialStatus"].value;
    const addressStreet = e.target["techgest--input-addressName"].value;
    const addressNumber = e.target["techgest--input-addressNumber"].value;
    const addressState = e.target["techgest--input-addressState"].value;
    const addressCity = e.target["techgest--input-addressCity"].value;
    const addressReference = e.target["techgest--input-addressReference"].value;

    const response = await ProjectApi.create({
      name,
      alias,
      clientName,
      estimatedCost,
      initialStatus,
      addressStreet,
      addressState,
      addressCity,
      addressNumber,
      addressReference,
    });

    if (!response.ok) {
      alert(response.message);
      return;
    }

    setMessage({
      text: "Obra criada com sucesso!",
      severity: "success",
    });
    setCreating(false);
    await fetchProjects();
  };

  const handleProjectUpdating = async (e) => {
    e.preventDefault();
    setEditing(true);

    const name = e.target["techgest--input-projectName"].value;
    const alias = e.target["techgest--input-projectAlias"].value;
    const clientName = e.target["techgest--input-projectClientName"].value;
    const estimatedCost = e.target["techgest--input-projectEstimatedCost"].value;
    const status = e.target["techgest--input-status"].value;
    const addressStreet = e.target["techgest--input-addressName"].value;
    const addressNumber = e.target["techgest--input-addressNumber"].value;
    const addressState = e.target["techgest--input-addressState"].value;
    const addressCity = e.target["techgest--input-addressCity"].value;
    const addressReference = e.target["techgest--input-addressReference"].value;

    const response = await ProjectApi.update(currentProject.id, {
      name,
      alias,
      clientName,
      estimatedCost,
      status,
      addressStreet,
      addressState,
      addressCity,
      addressNumber,
      addressReference,
    });

    if (!response.ok) {
      alert(response.message);
      return;
    }

    setMessage({
      text: "Obra atualizada com sucesso!",
      severity: "success",
    });
    setCurrentProject({});
    setEditing(false);
    await fetchProjects();
  };

  const deleteProject = async (project) => {
    if (!confirm(`Deseja realmente excluir o projeto "${project.name}"?`)) {
      return;
    }

    await ProjectApi.destroy(project.id);
    await fetchProjects();
  };

  const citiesSelectButton = (defaultCity = null) => {
    return (
      <Select
        name="techgest--input-addressCity"
        id="techgest--input-addressCity"
        defaultValue={defaultCity ? defaultCity : "invalid"}
        required
      >
        <MenuItem key={"invalid"} value={"invalid"} selected={!defaultCity}>
          Selecione uma cidade
        </MenuItem>
        {cities.map((city) => (
          <MenuItem key={city} value={city} selected={defaultCity && defaultCity === city}>
            {city}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const fetchProjects = async () => {
    setLoading(true);
    const response = await ProjectApi.list();
    setLoading(false);

    if (!response.ok) {
      console.error(response);
      return;
    }

    setProjects(response.projects);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const prettyStatus = (status) => {
    const map = {
      active: {
        name: "Ativa",
        btnType: "info",
      },
      pending: {
        name: "Pendente",
        btnType: "black",
      },
      finished: {
        name: "Finalizada",
        btnType: "green",
      },
      closed: {
        name: "Fechada",
        btnType: "red",
      },
    };

    return map[status] ?? map.pending;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadCrumbRoutes={[
          {
            name: "Obras",
            uri: "/obras",
          },
        ]}
      />
      {message.text && (
        <Alert onClose={resetMessage} severity={message.severity}>
          {message.text}
        </Alert>
      )}
      <Modal
        open={creating}
        onClose={() => setCreating(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <SoftBox sx={{ ...style }}>
          <p id="child-modal-description">Cadastrar obra</p>

          <Divider />

          {/* Project creation form */}
          <SoftBox component="form" role="form" onSubmit={handleProjectCreation}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Nome:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-projectName"
                id="techgest--input-projectName"
                placeholder="Ex.: Casa 01 - Loteamento Parque das Ilhas"
                required
              />
            </SoftBox>

            <SoftBox mb={2} display="flex">
              <SoftBox mb={2} mr={1} style={{ flexBasis: "50%" }}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Apelido (interno):
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-projectAlias"
                  id="techgest--input-projectAlias"
                  placeholder="Ex.: Casa de Fulano"
                />
              </SoftBox>
              <SoftBox mb={2} ml={1} style={{ flexBasis: "50%" }}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Nome do cliente:
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-projectClientName"
                  id="techgest--input-projectClientName"
                  placeholder="Fulano"
                />
              </SoftBox>
            </SoftBox>

            <SoftBox mb={2} display="flex">
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Custo estimado:
                  </SoftTypography>
                </SoftBox>
                <CurrencyInput
                  onChangeValue={console.log}
                  InputElement={
                    <SoftInput
                      type="text"
                      name="techgest--input-projectEstimatedCost"
                      id="techgest--input-projectEstimatedCost"
                      placeholder="R$ 99.999,99"
                      required
                    />
                  }
                />
              </SoftBox>

              <SoftBox mb={2} ml={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Status inicial:
                  </SoftTypography>
                </SoftBox>
                <Select
                  name="techgest--input-projectInitialStatus"
                  id="techgest--input-projectInitialStatus"
                  defaultValue="active"
                  required
                >
                  <MenuItem value="active">Ativa</MenuItem>
                  <MenuItem value="pending">Pendente</MenuItem>
                  <MenuItem value="finished">Finalizada</MenuItem>
                  <MenuItem value="closed">Fechada</MenuItem>
                </Select>
              </SoftBox>
            </SoftBox>

            <Divider />

            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Logradouro:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-addressName"
                id="techgest--input-addressName"
                placeholder="Rua 1..."
                required
              />
            </SoftBox>

            <SoftBox display="flex">
              <SoftBox mb={2} mr={1} width={"50%"}>
                <SoftBox>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Estado:
                  </SoftTypography>
                </SoftBox>
                <Select
                  name="techgest--input-addressState"
                  id="techgest--input-addressState"
                  defaultValue={StatesData.states[0].abbr}
                  required
                  onChange={({ target }) => {
                    setCities(StatesData.states.find((s) => s.abbr === target.value).cities);
                  }}
                >
                  {StatesData.states.map((state, i) => (
                    <MenuItem key={state.name} value={state.abbr}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </SoftBox>
              <SoftBox mb={2} ml={1} width={"50%"}>
                <SoftBox>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Cidade:
                  </SoftTypography>
                </SoftBox>
                {citiesSelectButton()}
              </SoftBox>
            </SoftBox>

            <SoftBox mb={2} display="flex">
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Número (opcional):
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-addressNumber"
                  id="techgest--input-addressNumber"
                  placeholder="200B"
                />
              </SoftBox>

              <SoftBox mb={2} ml={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Referencia (opcional):
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-addressReference"
                  id="techgest--input-addressReference"
                  placeholder="Perto de..."
                />
              </SoftBox>
            </SoftBox>

            <Divider />

            <SoftBox display="flex">
              <SoftBox mr={2}>
                <SoftButton type="button" color="white" onClick={() => setCreating(false)} mr={2}>
                  Cancelar
                </SoftButton>
              </SoftBox>

              <SoftBox mr={2}>
                <SoftButton type="submit" color="success">
                  Salvar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {/* End project creation form */}
        </SoftBox>
      </Modal>

      <Modal
        open={editing}
        onClose={() => {
          setEditing(false);
          setCurrentProject({});
        }}
        aria-labelledby="edicao-de-obra"
        aria-describedby="edicao-de-obra"
      >
        <SoftBox sx={{ ...style }}>
          <p id="child-modal-description">{currentProject.name}</p>

          <Divider />

          {/* Project creation form */}
          <SoftBox component="form" role="form" onSubmit={handleProjectUpdating}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Nome:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-projectName"
                id="techgest--input-projectName"
                placeholder="Ex.: Casa 01 - Loteamento Parque das Ilhas"
                defaultValue={currentProject.name}
                required
              />
            </SoftBox>

            <SoftBox mb={2} display="flex">
              <SoftBox mb={2} mr={1} style={{ flexBasis: "50%" }}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Apelido (interno):
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-projectAlias"
                  id="techgest--input-projectAlias"
                  placeholder="Ex.: Casa de Fulano"
                  defaultValue={currentProject.alias}
                />
              </SoftBox>
              <SoftBox mb={2} ml={1} style={{ flexBasis: "50%" }}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Nome do cliente:
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-projectClientName"
                  id="techgest--input-projectClientName"
                  placeholder="Fulano"
                  defaultValue={currentProject.clientName}
                />
              </SoftBox>
            </SoftBox>

            <SoftBox mb={2} display="flex">
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Custo estimado:
                  </SoftTypography>
                </SoftBox>
                <CurrencyInput
                  onChangeValue={console.log}
                  defaultValue={currentProject.estimatedCost}
                  InputElement={
                    <SoftInput
                      type="text"
                      name="techgest--input-projectEstimatedCost"
                      id="techgest--input-projectEstimatedCost"
                      placeholder="R$ 99.999,99"
                    />
                  }
                />
              </SoftBox>

              <SoftBox mb={2} ml={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Status:
                  </SoftTypography>
                </SoftBox>
                <Select
                  name="techgest--input-status"
                  id="techgest--input-status"
                  defaultValue={currentProject.status}
                  required
                >
                  <MenuItem value="active">Ativa</MenuItem>
                  <MenuItem value="pending">Pendente</MenuItem>
                  <MenuItem value="finished">Finalizada</MenuItem>
                  <MenuItem value="closed">Fechada</MenuItem>
                </Select>
              </SoftBox>
            </SoftBox>

            <Divider />

            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Logradouro:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-addressName"
                id="techgest--input-addressName"
                placeholder="Rua 1..."
                required
                defaultValue={currentProject.addressStreet}
              />
            </SoftBox>

            <SoftBox display="flex">
              <SoftBox mb={2} mr={1} width={"50%"}>
                <SoftBox>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Estado:
                  </SoftTypography>
                </SoftBox>
                <Select
                  name="techgest--input-addressState"
                  id="techgest--input-addressState"
                  defaultValue={currentProject.addressState}
                  required
                  onChange={({ target }) => {
                    setCities(StatesData.states.find((s) => s.abbr === target.value).cities);
                  }}
                >
                  {StatesData.states.map((state, i) => (
                    <MenuItem key={state.name} defaultValue={state.abbr}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </SoftBox>
              <SoftBox mb={2} ml={1} width={"50%"}>
                <SoftBox>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Cidade:
                  </SoftTypography>
                </SoftBox>
                {citiesSelectButton(currentProject.addressCity)}
              </SoftBox>
            </SoftBox>

            <SoftBox mb={2} display="flex">
              <SoftBox mb={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Número (opcional):
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-addressNumber"
                  id="techgest--input-addressNumber"
                  placeholder="200B"
                  defaultValue={currentProject.addressNumber}
                />
              </SoftBox>

              <SoftBox mb={2} ml={2}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Referencia (opcional):
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-addressReference"
                  id="techgest--input-addressReference"
                  placeholder="Perto de..."
                  defaultValue={currentProject.addressReference}
                />
              </SoftBox>
            </SoftBox>

            <Divider />

            <SoftBox display="flex">
              <SoftBox mr={2}>
                <SoftButton
                  type="button"
                  color="white"
                  onClick={() => {
                    setEditing(false);
                    setCurrentProject({});
                  }}
                  mr={2}
                >
                  Cancelar
                </SoftButton>
              </SoftBox>

              <SoftBox mr={2}>
                <SoftButton type="submit" color="success">
                  Salvar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {/* End project creation form */}
        </SoftBox>
      </Modal>

      <SoftBox py={3}>
        <SoftButton color="success" onClick={() => setCreating(true)}>
          + Cadastrar obra
        </SoftButton>
      </SoftBox>
      <SoftBox py={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Obras</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <TableContainer>
              <Table>
                <SoftBox component="thead">
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"center"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    #
                  </SoftBox>
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"left"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    Nome
                  </SoftBox>
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"left"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    Progresso
                  </SoftBox>
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"left"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    Status
                  </SoftBox>
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"center"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    Acao
                  </SoftBox>
                </SoftBox>

                <TableBody>
                  {loading && (
                    <SoftBox component="tr">
                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"center"}
                        colSpan={5}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <FontAwesomeIcon icon={faSpinner} spin />
                      </SoftBox>
                    </SoftBox>
                  )}
                  {projects.map((project, index) => (
                    <SoftBox component="tr" key={project.id}>
                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"center"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="secondary"
                          sx={{ display: "inline-block", width: "max-content" }}
                        >
                          {index + 1}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"left"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <a href={`/obras/${project.slug}`}>
                          <SoftTypography
                            fontWeight="regular"
                            fontSize={typography.size.sm}
                            color="secondary"
                            sx={{
                              display: "inline-block",
                              width: "max-content",
                              textDecoration: "underline",
                            }}
                          >
                            {project.name}
                          </SoftTypography>
                        </a>
                      </SoftBox>

                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"left"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <SoftBox display="flex" alignItems="center">
                          <SoftTypography variant="caption" color="text" fontWeight="medium">
                            {project.progress}%&nbsp;
                          </SoftTypography>
                          <SoftBox width="8rem">
                            <SoftProgress
                              defaultValue={project.progress}
                              color="dark"
                              variant="gradient"
                              label={false}
                            />
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"left"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <SoftTypography
                          variant="button"
                          color={prettyStatus(project.status).btnType}
                          fontWeight="normal"
                        >
                          {prettyStatus(project.status).name}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"right"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <SoftButton color="info" href={`/obras/${project.slug}/eap`}>
                          EAP
                        </SoftButton>
                        <SoftButton
                          color="white"
                          onClick={() => {
                            setEditing(true);
                            setCurrentProject(project);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </SoftButton>
                        <SoftButton color="error" onClick={() => deleteProject(project)}>
                          <FontAwesomeIcon icon={faTrashCan} />
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Projects;
