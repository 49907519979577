import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import SoftButton from "components/SoftButton";
import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import colors from "assets/theme/base/colors";
import "./styles/eap-styles.css";

import "react-contexify/ReactContexify.css";
import { useParams } from "react-router-dom";
import { ProjectApi } from "services/Project";
import EapView from "./components/eap-view";
import EapEdit from "./components/eap-edit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faSpinner } from "@fortawesome/free-solid-svg-icons";

function ProjectEap() {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [steps, setSteps] = useState([]);
  const [loadingProject, setLoadingProject] = useState(true);

  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  const [currentView, setCurrentView] = useState("graph");

  const fetchProject = async () => {
    const response = await ProjectApi.getBySlug(slug);
    setLoadingProject(false);

    if (!response.ok) {
      return;
    }

    setProject(response.project);
    setSteps(response.steps);
  };

  useEffect(() => {
    fetchProject();
  }, []);

  if (loadingProject) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <h1>Carregando..</h1>
      </DashboardLayout>
    );
  }

  if (!loadingProject && !project) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <h1>Obra não encontrada</h1>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadCrumbRoutes={[
          {
            name: "Obras",
            uri: "/obras",
          },
          {
            name: project.name,
            uri: `/obras/${project.slug}`,
          },
          {
            name: "EAP - Gerenciamento",
            uri: `/obras/${project.slug}/eap`,
          },
        ]}
      />
      <SoftTypography>
        <strong>EAP - Gerenciamento</strong>
      </SoftTypography>
      <Card style={{ position: "static" }}>
        <SoftBox
          px={3}
          py={3}
          borderBottom={1}
          borderColor={colors.grey["200"]}
          bgColor={"white"}
          variant={"contained"}
        >
          <SoftButton
            color="info"
            variant={currentView == "graph" ? "gradient" : "outlined"}
            onClick={async () => {
              setCurrentView("graph");
              await fetchProject();
            }}
          >
            Visualizacao
          </SoftButton>
          <SoftButton
            style={{ marginLeft: 20 }}
            color="warning"
            variant={currentView == "edit" ? "gradient" : "outlined"}
            onClick={() => setCurrentView("edit")}
          >
            Edicao
          </SoftButton>
          <SoftButton
            style={{ marginLeft: 20 }}
            color="white"
            variant="contained"
            onClick={async () => {
              await fetchProject();
            }}
          >
            <FontAwesomeIcon icon={loadingProject ? faSpinner : faRefresh} spin={loadingProject} />
          </SoftButton>
        </SoftBox>

        {project && !loadingProject && currentView === "graph" && (
          <EapView project={project} steps={steps} onEdit={fetchProject} />
        )}

        {project && !loadingProject && currentView === "edit" && (
          <EapEdit project={project} steps={steps} onEdit={fetchProject} />
        )}
      </Card>

      <Footer />
    </DashboardLayout>
  );
}

export default ProjectEap;
