import SoftBox from "components/SoftBox";

import colors from "assets/theme/base/colors";

import "react-contexify/ReactContexify.css";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { CurrencyInput } from "react-currency-mask";
import "./style.css";
import { ProjectApi } from "services/Project";
import { Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";

function EapEdit({ project, steps, onEdit = async () => {} }) {
  const [loadingCreateStep, setLoadingCreateStep] = useState(false);
  const [loadingDeleteStep, setLoadingDeleteStep] = useState(null);
  const [loadingAddStepInside, setLoadingAddStepInside] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(null);
  const inputsRef = useRef({});

  const handleRemoveProgress = async (s) => {
    const r = Number(
      prompt(`Qual o valor do progresso que deseja remover em %? (Máximo: ${s.progress}%)`, "0")
    );

    if (r == 0) {
      return;
    }

    setLoadingProgress(s.id);
    const response = await ProjectApi.removeProgressFromStep(s.id, r);
    setLoadingProgress(null);

    if (!response.ok) {
      alert(response.message);
      return;
    }

    await onEdit();
  };

  const handleProgress = async (s) => {
    const maxProgress = 100 - s.progress;
    const r = Number(
      prompt(`Qual o valor do progresso que deseja registrar em %? (Máximo: ${maxProgress}%)`, "0")
    );

    if (r == 0) {
      return;
    }

    setLoadingProgress(s.id);
    const response = await ProjectApi.addProgressToStep(s.id, r);
    setLoadingProgress(null);

    if (!response.ok) {
      alert(response.message);
      return;
    }

    await onEdit();
  };

  const addStep = async () => {
    setLoadingCreateStep(true);
    const response = await ProjectApi.createEmptyStep(project.id);
    setLoadingCreateStep(false);

    if (!response.ok) {
      alert(response.message);
      return;
    }

    await onEdit();
  };

  const addStepInside = async (s) => {
    setLoadingAddStepInside(s.id);
    const response = await ProjectApi.createEmptyStepInside(project.id, s.id);
    setLoadingAddStepInside(null);

    if (!response.ok) {
      alert(response.message);
      return;
    }

    await onEdit();
  };

  const removeStep = async ({ id }) => {
    setLoadingDeleteStep(id);
    const response = await ProjectApi.deleteStep(id);
    setLoadingDeleteStep(null);

    if (!response.ok) {
      alert(response.message);
      return;
    }

    await onEdit();
  };

  const renderStep = (s, level = 0) => {
    return (
      <SoftBox
        className={[s.children.length > 0 ? "fold-container" : ""].join(" ")}
        style={{ maxWidth: "100%" }}
        component="form"
      >
        <SoftBox
          style={{ marginTop: 10, paddingLeft: level * 30 }}
          lineHeight={1}
          key={s.id}
          className={[level > 0 ? "fold" : "", "line"].join(" ")}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7}>
              <SoftTypography variant="caption" fontWeight="regular" color="text">
                Progresso: {s.progress}%
              </SoftTypography>
              <SoftInput
                id={`techgest--input-eap-step-name-${s.id}`}
                name={`techgest--input-eap-step-name-${s.id}`}
                sx={{ flexBasis: 5000 }}
                placeholder="Nome da etapa"
                autoFocus={s.name.length == 0}
                defaultValue={s.name}
                className="z-index-2 name-input"
                onChange={(e) => onStepEdit(s, e)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2} className="z-index-2">
              <SoftTypography variant="caption" fontWeight="regular" color="text">
                Custo estimado:
              </SoftTypography>
              <CurrencyInput
                defaultValue={s.estimatedCost}
                onChangeValue={(e) => onStepEdit(s, e)}
                InputElement={
                  <SoftInput
                    id={`techgest--input-eap-step-estimatedCost-${s.id}`}
                    name={`techgest--input-eap-step-estimatedCost-${s.id}`}
                    placeholder="R$ 0.00"
                    onChange={(e) => onStepEdit(s, e)}
                    defaultValue={s.estimatedCost}
                  />
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} className="z-index-2">
              <SoftTypography variant="caption" fontWeight="regular" color="text">
                Acoes
              </SoftTypography>
              <br />
              <SoftButton
                variant="contained"
                color="white"
                onClick={() => handleRemoveProgress(s)}
                title="Remover progresso"
              >
                <FontAwesomeIcon
                  icon={loadingProgress && loadingProgress == s.id ? faSpinner : faArrowLeft}
                  spin={loadingProgress && loadingProgress == s.id}
                />{" "}
                %
              </SoftButton>
              <SoftButton
                variant="contained"
                color="white"
                onClick={() => handleProgress(s)}
                title="Adicionar progresso"
              >
                %{" "}
                <FontAwesomeIcon
                  icon={loadingProgress && loadingProgress == s.id ? faSpinner : faArrowRight}
                  spin={loadingProgress && loadingProgress == s.id}
                />
              </SoftButton>
              <SoftButton
                variant="contained"
                color="white"
                onClick={() => removeStep(s)}
                disabled={loadingDeleteStep && loadingDeleteStep == s.id}
              >
                <SoftTypography color="error">
                  <FontAwesomeIcon
                    icon={loadingDeleteStep && loadingDeleteStep == s.id ? faSpinner : faTrash}
                    spin={loadingDeleteStep && loadingDeleteStep == s.id}
                  />
                </SoftTypography>
              </SoftButton>
              <SoftButton variant="contained" color="white" onClick={() => addStepInside(s)}>
                <FontAwesomeIcon
                  icon={loadingAddStepInside && loadingAddStepInside == s.id ? faSpinner : faPlus}
                  spin={loadingAddStepInside && loadingAddStepInside == s.id}
                />
              </SoftButton>
            </Grid>
          </Grid>
        </SoftBox>
        {s.children.length > 0 && s.children.map((c) => renderStep(c, level + 1))}
      </SoftBox>
    );
  };

  const onStepEdit = (s, e) => {
    if (inputsRef.current[s.id]) {
      console.log("Timeout cleared");
      clearTimeout(inputsRef.current[s.id]);
    }

    const timeoutId = setTimeout(async () => {
      const form = e.target.closest("form");
      const name = form[`techgest--input-eap-step-name-${s.id}`].value || "";
      const estimatedCost = form[`techgest--input-eap-step-estimatedCost-${s.id}`].value || "";

      const response = await ProjectApi.updateStep(s.id, {
        name,
        estimatedCost,
      });

      if (!response.ok) {
        console.error(response.message);
      }
    }, 1000);

    inputsRef.current[s.id] = timeoutId;
  };

  return (
    <SoftBox id="eap-edit-container" style={{ position: "relative" }}>
      <SoftBox py={3} px={3} borderBottom={1} borderColor={colors.grey["300"]}>
        <SoftBox
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
          lineHeight={1}
        >
          {steps.map((s) => renderStep(s))}
          <SoftButton
            variant="outlined"
            color="black"
            style={{ marginTop: 20 }}
            onClick={addStep}
            disabled={loadingCreateStep}
          >
            {!loadingCreateStep && <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />}
            {loadingCreateStep && (
              <FontAwesomeIcon icon={faSpinner} style={{ marginRight: 10 }} spin={true} />
            )}
            Adicionar etapa
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default EapEdit;
