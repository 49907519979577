import { useAuth } from "hooks/useAuthHook";
import { useLayoutEffect } from "react";

export const Logout = () => {
  const { logout } = useAuth();

  useLayoutEffect(() => {
    (async () => {
      await logout();
    })();
  }, []);

  return <div></div>;
};
