import { HTTP_OK } from "config/constants/http";
import { BaseApi } from "./BaseApi";

export const MetricsApi = {
  dashboard: async () => {
    const response = await BaseApi.make("/api/metrics/dashboard", "GET");

    if (response.status != HTTP_OK) {
      return {
        ok: false,
        message: response.data.message,
      };
    }

    return {
      ok: true,
      message: "",
      data: response.data,
    };
  },
};
