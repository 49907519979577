import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";

import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Projects from "layouts/projects";
import { Logout } from "layouts/logout";
import Materials from "layouts/materials";
import Cube from "examples/Icons/Cube";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
    protection: "auth",
  },
  {
    type: "collapse",
    name: "Obras",
    key: "obras",
    route: "/obras",
    icon: <Office size="12px" />,
    component: <Projects />,
    noCollapse: true,
    protection: "auth",
  },
  {
    type: "collapse",
    name: "Materiais",
    key: "materiais",
    route: "/materiais",
    icon: <Cube size="12px" />,
    component: <Materials />,
    noCollapse: true,
    protection: "auth",
  },
  { type: "title", title: "Conta", key: "account-pages" },
  {
    type: "collapse",
    name: "Meu perfil",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
    protection: "auth",
  },
  {
    type: "collapse",
    name: "Sair",
    key: "logout",
    route: "/logout",
    icon: null,
    component: <Logout />,
    noCollapse: true,
    protection: "auth",
  },
];

export default routes;
