import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import SoftButton from "components/SoftButton";
import { useState } from "react";
import colors from "assets/theme/base/colors";
import "./styles.css";

import "react-contexify/ReactContexify.css";

function EapView({ project, steps }) {
  const [expandedIds, setExpandedIds] = useState(new Set());
  const [eapRows, setEapRows] = useState([
    {
      name: project.name,
      progress: 0,
      children: steps,
    },
  ]);

  const handleStepClick = (step, currentLineLevel, e) => {
    setExpandedIds((e) => {
      eapRows[currentLineLevel].children.forEach((s) => e.delete(s.id));

      e.add(step.id);
      return e;
    });

    if (eapRows.length < 1) {
      setEapRows((last) => {
        last.push(step);

        return last;
      });

      return;
    }

    const copy = [...eapRows];
    const lastRow = copy.pop();

    if (step.level === lastRow.level) {
      setEapRows([...copy, step]);
      return;
    }

    if (step.level < lastRow.level) {
      setEapRows((last) => {
        const limit = last.length - (lastRow.level - step.level);
        const sliced = last.slice(0, limit);

        return sliced;
      });
      return;
    }

    setEapRows((l) => [...l, step]);
  };

  return (
    <SoftBox id="eap-view-container" style={{ position: "relative" }}>
      <SoftBox py={3} px={3} borderBottom={1} borderColor={colors.grey["300"]}>
        <SoftBox
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
          lineHeight={1}
        >
          <SoftButton
            color="black"
            variant={"contained"}
            disabled={false}
            id={`step-0-btn`}
            className={`step-0-child expanded-step`}
          >
            {project.name}
          </SoftButton>
        </SoftBox>
      </SoftBox>

      {eapRows.map((row, currentLineLevel) => {
        return (
          <SoftBox
            py={3}
            px={3}
            borderBottom={1}
            borderColor={colors.grey["300"]}
            key={row.name}
            style={{ position: "relative" }}
            minHeight={80}
          >
            <SoftBox
              sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
              lineHeight={1}
            >
              {row.children.length < 1 && (
                <SoftTypography variant="caption" color="text">
                  Nenhuma etapa cadastrada dentro da etapa <strong>{`"${row.name}"`}</strong> ainda.
                  <br />
                  Você pode adicionar uma clicando no botão laranja -&gt;
                </SoftTypography>
              )}
              {row.children.map((step) => (
                <SoftButton
                  key={step.name}
                  color={expandedIds.has(step.id) ? "black" : "white"}
                  onClick={(e) => handleStepClick(step, currentLineLevel, e)}
                  disabled={false}
                  id={`step-${step.id}-btn`}
                  className={`step-${row.id}-child ${expandedIds.has(step.id) ? "expanded-step" : ""}`}
                >
                  {step.name} <br /> {step.progress}%
                </SoftButton>
              ))}
            </SoftBox>
          </SoftBox>
        );
      })}
    </SoftBox>
  );
}

export default EapView;
