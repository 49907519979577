// @mui material components
import Card from "@mui/material/Card";
import {
  Alert,
  Divider,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { useEffect, useMemo, useState } from "react";
import SoftInput from "components/SoftInput";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import SoftProgress from "components/SoftProgress";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import StatesData from "resources/data/br-states.json";

import { CurrencyInput } from "react-currency-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { MaterialApi } from "services/Material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  maxHeight: "100vh",
};

function Materials() {
  const [creating, setCreating] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    severity: "success",
  });
  const [cities, setCities] = useState(StatesData.states[0].cities);
  const [materials, setMaterials] = useState([]);
  const [editing, setEditing] = useState(false);
  const [currentMaterial, setCurrentMaterial] = useState({});
  const [deleting, setDeleting] = useState(null);

  const resetMessage = () => setMessage({ text: "", severity: "" });

  const handleMaterialCreation = async (e) => {
    e.preventDefault();
    setCreating(true);

    const name = e.target["techgest--input-materialName"].value;
    const unit = e.target["techgest--input-materialUnit"].value;
    const price = e.target["techgest--input-materialPrice"].value;

    const response = await MaterialApi.create({
      name,
      unit,
      price,
    });

    if (!response.ok) {
      alert(response.message);
      return;
    }

    setMessage({
      text: response.message,
      severity: "success",
    });
    setCreating(false);
    await fetchMaterials();
  };

  const handleMaterialUpdating = async (e) => {
    e.preventDefault();
    setEditing(true);

    const name = e.target["techgest--input-materialName"].value;
    const unit = e.target["techgest--input-materialUnit"].value;
    const price = e.target["techgest--input-materialPrice"].value;

    const response = await MaterialApi.update(currentMaterial.id, {
      name,
      unit,
      price,
    });

    if (!response.ok) {
      alert(response.message);
      return;
    }

    setMessage({
      text: response.message,
      severity: "success",
    });
    setCurrentMaterial({});
    setEditing(false);
    await fetchMaterials();
  };

  const deleteMaterial = async (project) => {
    if (!confirm(`Deseja realmente excluir o material "${project.name}"?`)) {
      return;
    }

    await MaterialApi.destroy(project.id);
    await fetchMaterials();
  };

  const fetchMaterials = async () => {
    const response = await MaterialApi.list();

    if (!response.ok) {
      console.error(response);
      return;
    }

    setMaterials(response.materials);
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadCrumbRoutes={[
          {
            name: "materiais",
            uri: "/materiais",
          },
        ]}
      />
      {message.text && (
        <Alert onClose={resetMessage} severity={message.severity}>
          {message.text}
        </Alert>
      )}
      <Modal
        open={creating}
        onClose={() => setCreating(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <SoftBox sx={{ ...style }}>
          <p id="child-modal-description">Cadastrar material</p>

          <Divider />

          {/* Material creation form */}
          <SoftBox component="form" role="form" onSubmit={handleMaterialCreation}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Nome:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-materialName"
                id="techgest--input-materialName"
                placeholder="Milheiro de bloco"
                required
              />
            </SoftBox>

            <SoftBox mb={2} display="flex">
              <SoftBox mb={2} mr={1} style={{ flexBasis: "50%" }}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Unidade:
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-materialUnit"
                  id="techgest--input-materialUnit"
                  placeholder="Ex.: KG"
                />
              </SoftBox>
              <SoftBox mb={2} ml={1} style={{ flexBasis: "50%" }}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Preco/unidade (opcional):
                  </SoftTypography>
                </SoftBox>
                <CurrencyInput
                  onChangeValue={console.log}
                  InputElement={
                    <SoftInput
                      type="text"
                      name="techgest--input-materialPrice"
                      id="techgest--input-materialPrice"
                      placeholder="R$ 99.999,99"
                      required
                    />
                  }
                />
              </SoftBox>
            </SoftBox>

            <Divider />

            <SoftBox display="flex">
              <SoftBox mr={2}>
                <SoftButton type="button" color="white" onClick={() => setCreating(false)} mr={2}>
                  Cancelar
                </SoftButton>
              </SoftBox>

              <SoftBox mr={2}>
                <SoftButton type="submit" color="success">
                  Salvar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {/* End material creation form */}
        </SoftBox>
      </Modal>

      <Modal
        open={editing}
        onClose={() => {
          setEditing(false);
          setCurrentMaterial({});
        }}
        aria-labelledby="edicao-de-material"
        aria-describedby="edicao-de-material"
      >
        <SoftBox sx={{ ...style }}>
          <p id="child-modal-description">{currentMaterial.name}</p>

          <Divider />

          {/* Material editing form */}
          <SoftBox component="form" role="form" onSubmit={handleMaterialUpdating}>
            <SoftBox mb={2}>
              <SoftBox mb={1} ml={0.5}>
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Nome:
                </SoftTypography>
              </SoftBox>
              <SoftInput
                type="text"
                name="techgest--input-materialName"
                id="techgest--input-materialName"
                placeholder="Milheiro de bloco"
                defaultValue={currentMaterial.name}
                required
              />
            </SoftBox>

            <SoftBox mb={2} display="flex">
              <SoftBox mb={2} mr={1} style={{ flexBasis: "50%" }}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Apelido (interno):
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  type="text"
                  name="techgest--input-materialUnit"
                  id="techgest--input-materialUnit"
                  defaultValue={currentMaterial.unit}
                  placeholder="Ex.: KG"
                />
              </SoftBox>
              <SoftBox mb={2} ml={1} style={{ flexBasis: "50%" }}>
                <SoftBox mb={1} ml={0.5}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Preco/unidade (opcional):
                  </SoftTypography>
                </SoftBox>
                <CurrencyInput
                  onChangeValue={console.log}
                  defaultValue={currentMaterial.price}
                  InputElement={
                    <SoftInput
                      type="text"
                      name="techgest--input-materialPrice"
                      id="techgest--input-materialPrice"
                      placeholder="R$ 99.999,99"
                      required
                    />
                  }
                />
              </SoftBox>
            </SoftBox>

            <Divider />

            <SoftBox display="flex">
              <SoftBox mr={2}>
                <SoftButton type="button" color="white" onClick={() => setCreating(false)} mr={2}>
                  Cancelar
                </SoftButton>
              </SoftBox>

              <SoftBox mr={2}>
                <SoftButton type="submit" color="success">
                  Salvar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          {/* End material editing form */}
        </SoftBox>
      </Modal>

      <SoftBox py={3}>
        <SoftButton color="success" onClick={() => setCreating(true)}>
          + Cadastrar material
        </SoftButton>
      </SoftBox>
      <SoftBox py={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Materiais</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <TableContainer>
              <Table>
                <SoftBox component="thead">
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"center"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    #
                  </SoftBox>
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"left"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    Nome
                  </SoftBox>
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"left"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    Unidade
                  </SoftBox>
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"left"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    Preco
                  </SoftBox>
                  <SoftBox
                    component="th"
                    width={"auto"}
                    pt={1.5}
                    pb={1.25}
                    pl={3}
                    textAlign={"center"}
                    fontSize={typography.size.xxs}
                    fontWeight={"bold"}
                    color="secondary"
                    opacity={0.7}
                    borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                  >
                    Acao
                  </SoftBox>
                </SoftBox>

                <TableBody>
                  {materials.map((material, index) => (
                    <SoftBox component="tr" key={material.id}>
                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"center"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="secondary"
                          sx={{ display: "inline-block", width: "max-content" }}
                        >
                          {index + 1}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"left"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <SoftTypography
                          fontWeight="regular"
                          fontSize={typography.size.sm}
                          color="secondary"
                          sx={{
                            display: "inline-block",
                            width: "max-content",
                            textDecoration: "underline",
                          }}
                        >
                          {material.name}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"left"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <SoftTypography
                          fontWeight="regular"
                          fontSize={typography.size.sm}
                          color="secondary"
                          sx={{
                            display: "inline-block",
                            width: "max-content",
                          }}
                        >
                          {material.unit}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"left"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <SoftTypography
                          fontWeight="regular"
                          fontSize={typography.size.sm}
                          color="secondary"
                          sx={{
                            display: "inline-block",
                            width: "max-content",
                          }}
                        >
                          {material.price}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox
                        component="td"
                        p={1}
                        textAlign={"right"}
                        borderBottom={`${borders.borderWidth[1]} solid ${colors.light.main}`}
                      >
                        <SoftButton
                          color="white"
                          onClick={() => {
                            setEditing(true);
                            setCurrentMaterial(material);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </SoftButton>
                        <SoftButton color="error" onClick={() => deleteMaterial(material)}>
                          <FontAwesomeIcon icon={faTrashCan} />
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Materials;
