const reportsBarChartData = {
  chart: {
    labels: ["Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    datasets: { label: "Avanco", data: [12, 30, 50, 4, 8, 18, 29, 71, 2] },
  },
  items: [
    {
      icon: { color: "primary", component: "library_books" },
      label: "etapas",
      progress: { content: "87", percentage: 60 },
    },
    {
      icon: { color: "info", component: "touch_app" },
      label: "tarefas",
      progress: { content: "40", percentage: 90 },
    },
    {
      icon: { color: "warning", component: "payment" },
      label: "gastos",
      progress: { content: "288.000", percentage: 30 },
    },
  ],
};

export default reportsBarChartData;
