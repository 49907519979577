import { Link, useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/pertrolina-catedral.webp";
import { AuthApi } from "services/Auth";

function SignUp() {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = e.target["techgest--input-name"].value;
    const username = e.target["techgest--input-username"].value;
    const email = e.target["techgest--input-email"].value;
    const password = e.target["techgest--input-password"].value;
    const passwordConfirmation = e.target["techgest--input-passwordConfirmation"].value;

    if (password !== passwordConfirmation) {
      alert("As senhas não conferem!");
      return;
    }

    const response = await AuthApi.register({
      name,
      username,
      email,
      password,
      passwordConfirmation,
    });

    if (!response.ok) {
      alert(response.message);
      return;
    }

    navigate("/dashboard");
  };

  return (
    <CoverLayout
      title="Crie sua conta agora"
      description="Preencha o formulário abaixo para criar sua conta"
      image={curved9}
      action={{
        type: "internal",
        route: "/entrar",
        label: "Entrar",
        color: "dark",
      }}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nome completo:
            </SoftTypography>
          </SoftBox>
          <SoftInput type="text" placeholder="" name="techgest--input-name" required />
        </SoftBox>

        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nome de usuário:
            </SoftTypography>
          </SoftBox>
          <SoftInput type="text" placeholder="" name="techgest--input-username" required />
        </SoftBox>

        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email:
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder="email@email.com"
            name="techgest--input-email"
            required
          />
        </SoftBox>

        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Senha:
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="********"
            name="techgest--input-password"
            required
          />
        </SoftBox>

        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Confirmacao de senha:
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="********"
            name="techgest--input-passwordConfirmation"
            required
          />
        </SoftBox>

        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" fullWidth type="submit">
            Cadastrar
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Não possui conta ainda?&nbsp;
            <SoftTypography
              component={Link}
              to="/entrar"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Já possuo uma conta
            </SoftTypography>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignUp;
