import { HTTP_OK, HTTP_CREATED } from "config/constants/http";
import { BaseApi } from "./BaseApi";
import { IS_LOGGED_STORAGE_KEY } from "config/constants/auth";

export const AuthService = {
  isLogged: () => {
    const isLoggedValue = localStorage.getItem(IS_LOGGED_STORAGE_KEY);
    return isLoggedValue && isLoggedValue == 1;
  },
  setIsLogged: (value) => {
    localStorage.setItem(IS_LOGGED_STORAGE_KEY, Number(value));
  },
};

export const AuthApi = {
  logout: async () => {
    try {
      const response = await BaseApi.make("/api/auth/logout", "DELETE");

      if (response.status != HTTP_OK) {
        console.error(response.data.message);
        throw new Error("Falha ao sair. Por favor, limpe os cookies e tente novamente");
      }

      AuthService.setIsLogged(0);

      return {
        ok: true,
      };
    } catch (e) {
      return {
        ok: false,
        message: e.message,
      };
    }
  },
  login: async (username, password) => {
    try {
      const response = await BaseApi.make("/api/auth/login", "POST", {
        username,
        password,
      });

      if (response.status != HTTP_OK) {
        throw new Error(response.data.message);
      }

      AuthService.setIsLogged(1);
      return {
        ok: true,
        message: "",
      };
    } catch (error) {
      console.error("Failed to login:", { error });
      return {
        ok: false,
        message: error.message,
      };
    }
  },
  register: async ({ name, email, username, password, passwordConfirmation }) => {
    try {
      const response = await BaseApi.make("/api/auth/register", "POST", {
        name,
        email,
        username,
        password,
        password_confirmation: passwordConfirmation,
      });

      if (response.status != HTTP_CREATED) {
        throw new Error(response.data.message);
      }

      AuthService.setIsLogged(1);

      return {
        ok: true,
        message: "",
      };
    } catch (error) {
      console.error("Failed to login:", { error });
      return {
        ok: false,
        message: error.message,
      };
    }
  },
};
