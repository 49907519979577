import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import typography from "assets/theme/base/typography";

import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

import { MetricsApi } from "services/Metrics";
import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function Dashboard() {
  const { size } = typography;
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState({
    dates: [],
    inprogressProjectsCount: 0,
    totalSteps: 0,
    finishedSteps: 0,
    extra: 0,
    tasks: 0,
    expenses: 0,
    progresses: {},
    progressesForward: {},
    progressesBackwards: {},
  });

  const metricsToChart = useMemo(() => {
    const labels = metrics.dates;
    const datasets = {
      label: "Avancos",
      data: labels.map((label) =>
        metrics.progresses[label] ? metrics.progresses[label].length : 0
      ),
    };

    return {
      labels,
      datasets,
    };
  }, [metrics]);

  const metricsToItems = useMemo(() => {
    const items = [
      {
        icon: { color: "primary", component: "library_books" },
        label: "etapas finalizadas",
        progress: {
          content: metrics.finishedSteps,
          percentage: Math.floor((metrics.finishedSteps / metrics.totalSteps) * 100),
        },
      },
    ];

    return items;
  }, [metrics]);

  const metricsToLines = useMemo(() => {
    const labels = metrics.dates;
    const datasets = [];

    datasets.push({
      label: "Avancos (%)",
      color: "info",
      data: labels.map((label) =>
        metrics.progressesForward[label]
          ? metrics.progressesForward[label].reduce((acc, { progress }) => acc + progress, 0)
          : 0
      ),
    });

    datasets.push({
      label: "Recuos (%)",
      color: "red",
      data: labels.map((label) =>
        metrics.progressesBackwards[label]
          ? metrics.progressesBackwards[label].reduce(
            (acc, { progress }) => acc + Math.abs(progress),
            0
          )
          : 0
      ),
    });

    console.log({ datasets });

    return {
      labels,
      datasets,
    };
  }, [metrics]);

  const loadMetrics = async () => {
    setLoading(true);
    const response = await MetricsApi.dashboard();
    setLoading(false);

    if (!response.ok) {
      return;
    }

    setMetrics(response.data);
  };

  useEffect(() => {
    loadMetrics();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <MiniStatisticsCard
                  title={{ text: "Despesas totais" }}
                  count={metrics.expenses}
                  icon={{ color: "info", component: "paid" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <MiniStatisticsCard
                  title={{ text: "Obras em andamento" }}
                  count={metrics.inprogressProjectsCount}
                  icon={{ color: "info", component: "public" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <MiniStatisticsCard
                  title={{ text: "Etapas concluídas" }}
                  count={metrics.finishedSteps}
                  icon={{ color: "info", component: "emoji_events" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <MiniStatisticsCard
                  title={{ text: "Extra" }}
                  count={metrics.extra}
                  icon={{
                    color: "info",
                    component: "shopping_cart",
                  }}
                />
              )}
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title="Avancos"
                description={""}
                chart={metricsToChart}
                items={metricsToItems}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Andamento das EAP (em pontos percentuais)"
                description={
                  <SoftBox display="flex" alignItems="center">
                    {/* <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </SoftTypography>
                    </SoftTypography> */}
                  </SoftBox>
                }
                height="20.25rem"
                chart={metricsToLines}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {/* <OrderOverview /> */}
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
