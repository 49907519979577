import { useEffect, useMemo, useState } from "react";

import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import Table from "examples/Tables/Table";

import { ProjectApi } from "services/Project";
import SoftProgress from "components/SoftProgress";

function Projects() {
  const columns = [
    { name: "Projeto", align: "left" },
    // { name: "Membros", align: "left" },
    { name: "R$ estimativa", align: "center" },
    { name: "Progresso", align: "center" },
  ];
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    setLoading(true);
    const response = await ProjectApi.list();
    setLoading(false);

    if (!response.ok) {
      console.error(response);
      return;
    }

    setProjects(response.projects);
  };

  const rows = useMemo(() => {
    return projects.map((p) => {
      return {
        Projeto: (
          <SoftBox component="a" href={`/obras/${p.slug}`} sx={{ marginLeft: 2 }}>
            {p.name}
          </SoftBox>
        ),
        ["R$ estimativa"]: (
          <SoftTypography variant="caption" color="text" fontWeight="medium">
            R$ {p.estimatedCost}
          </SoftTypography>
        ),
        Progresso: (
          <SoftBox width="8rem" textAlign="left">
            <SoftProgress value={p.progress} color="info" variant="gradient" label={false} />
          </SoftBox>
        ),
      };
    });
  }, [projects]);

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SoftBox>
          <SoftTypography variant="h6" gutterBottom>
            Obras
          </SoftTypography>
          <SoftBox display="flex" alignItems="center" lineHeight={0}>
            {/*<Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              done
            </Icon>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              &nbsp;<strong>2 finalizados</strong> this month
            </SoftTypography> */}
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}
      >
        <Table columns={columns} rows={rows} />
      </SoftBox>
    </Card>
  );
}

export default Projects;
